<template>
  <div class="part">
    <div class="part-header">
      <div class="part-info">
        <div class="part-title" v-if="title">{{ title }}</div>
        <div class="part-desc" v-if="desc">{{ desc }}</div>
      </div>
      <div class="l-gap-buttons">
        <slot name="buttons">
        </slot>
      </div>
    </div>

    <div class="part-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    desc: String,
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.part {
  padding: 12px 0;
}

.part-header {
  margin-bottom: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid #333;
  display: flex;
  justify-content: space-between;
}

.part-body {

}

.part-title {
  font-size: 20px;
}

.part-desc {
  color: #777;
}
</style>