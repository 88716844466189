<template>
  <Modal
      :value="visible"
      @input="val => $emit('input', val)"
      @on-ok="onOk"
      @on-cancel="onCancel"
      :ok-text="okText"
      :cancel-text="cancelText"
      :title="title"
      :closable="closable"
      :loading="loading"
  >
    <slot></slot>
  </Modal>
</template>

<script>
export default {
  props: {
    value: {type: Boolean, default: false},
    closable: {
      type: Boolean,
      default: true
    },
    title: {type: String},
    okText: {type: String, default: '확인'},
    cancelText: {type: String, default: '취소'},
    loading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      visible: this.value,
    }
  },
  methods: {
    onOk() {
      this.$emit('on-ok')
    },
    onCancel() {
      this.$emit('on-cancel')
    }
  },
  watch: {
    value(val) {
      this.visible = val
    }
  }
}
</script>

<style lang="scss" scoped>
</style>