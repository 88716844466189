import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import BaseLayout from "@/layouts/BaseLayout";
import store from '@/store'
import ViewUI from 'view-design'
import Error404 from '@/views/error/404'
import InvalidRole from "@/views/error/InvalidRole";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      meta: {
        title: '로그인',
        public: true
      },
      component: () => import('@/views/Login')
    },
    {
      path: '/',
      name: 'root',
      component: BaseLayout,
      redirect: '/dashboard',
      meta: {
        title: '홈',
      },
      children: routes,
    },

    // Error
    {
      path: '/error/invalid-role',
      name: 'invalid-role',
      component: InvalidRole,
      meta: {
        title: '권한 없음',
      },
    },
    {
      path: '*',
      name: '404',
      component: Error404,
      meta: {
        title: '404',
      },
    },

  ]
})

export default router

router.beforeEach((to, from, next) => {
  ViewUI.LoadingBar.start();
  if (to.meta && !to.meta.public && !store.getters.authToken) {
    next({name: 'login', query: {redirect: to.fullPath}})
  } else {
    if (to.meta && to.meta.title) {
      document.title = `${to.meta.title} | 알고 어드민`
    }

    next()
  }
})

router.afterEach(route => {
  ViewUI.LoadingBar.finish();
})