<template>
  <div class="custom-paginator-container">
    <Page :total="total" :current="page"
          @on-change="onChange"></Page>
  </div>
</template>

<script>
export default {
  props: {
    total: {type: Number, default: 0},
    page: {type: Number, default: 1},
    length: {type: Number, default: 20},
  },
  methods: {
    onChange(num) {
      this.$emit('on-change', num)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>