<template>
  <MenuItem :name="name" :to="{name: name}" :style="style">
    <CIcon :name="icon"></CIcon>
    <span>
      <strong>{{ label }}</strong>
    </span>
  </MenuItem>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    style() {
      return {
        display: 'flex',
        alignItems: 'center',
      }
    }
  }
}
</script>

<style scoped lang="scss">
i {
  margin-right: 6px;
}
</style>