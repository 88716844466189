<template>
  <div class="custom-table-container">
    <div class="search-bar" v-if="isSearch">
      <Input size="large"
             search
             @on-search="onSearch"
             enter-button="검색"
             :value="value"
             @input="(val) => $emit('input', val)"
             :placeholder="searchPlaceholder"
      />
    </div>

    <div class="custom-table">
      <Table :data="data" :columns="tableHeaders"
             :show-header="showHeader"
             no-data-text="일치하는 데이터 없음"
             no-filtered-data-text="일치하는 데이터 없음"
             :loading="loading"
             :border="border"
             :stripe="stripe"
             :draggable="draggable"
      >
        <slot name="header" #header></slot>
        <slot name="loading" #loading></slot>
        <slot name="footer" #footer>
          <div class="total-count text-right">
            <span v-if="totalCount">총 {{ totalCount }} 항목</span>
          </div>
        </slot>
        <template v-for="sc in slotHeaders" #[sc.slot]="slotData">
          <template v-if="sc.view">
            <BooleanTag v-if="sc.view === 'bool'"
                        :value="slotData.row[sc.key]"
                        v-bind="sc.data"
            ></BooleanTag>
            <template v-else-if="sc.view === 'index'">
              {{slotData.index + 1}}
            </template>
            <template v-else-if="sc.view === 'link'">
              <router-link :to="{name: sc.target, params: {id: slotData.row[sc.id]}}">{{slotData.row[sc.key]}}</router-link>
            </template>
            <template v-else-if="sc.view === 'trim'">
              <div class="trim-text">{{trim(slotData.row[sc.key], sc.size)}}</div>
            </template>
          </template>
          <template v-else-if="sc.slot === 'action'">
            <div class="action-buttons">
              <slot name="action" v-bind="slotData"></slot>
            </div>
          </template>
          <slot :name="sc.slot" v-bind="slotData" v-else></slot>
        </template>
      </Table>
    </div>
    <div class="custom-paginator" v-if="!hidePaginator">
      <CPaginator
          :total="totalCount"
          :page="page"
          :length="length"
          @on-change="pageChange"
      ></CPaginator>
    </div>
  </div>

</template>

<script>
import headerSet from '@/data/headers'

export default {
  props: {
    headers: {type: Array},
    headerKey: {type: String},
    data: {type: Array},
    showHeader: {type: Boolean, default: true},
    loading: {type: Boolean},
    border: {type: Boolean, default: true},
    stripe: {type: Boolean},
    hidePaginator: {type: Boolean, default: false},
    totalCount: {type: Number},
    page: {type: Number, default: 1},
    length: {type: Number, default: 20},
    draggable: {type: Boolean, default: false},
    searchPlaceholder: {type: String, default: '검색어를 입력해주세요.'},
    value: {type: String},
  },
  data() {
    return {
    }
  },
  computed: {
    slotHeaders() {
      return _.filter(this.tableHeaders, item => !!item.slot)
    },
    tableHeaders() {
      return this.headerKey ? headerSet[this.headerKey] : this.headers;
    },
    isSearch() {
      return !!this.$listeners['on-search']
    }
  },
  methods: {
    pageChange(num) {
      this.$emit('page-change', num)
    },
    reload() {
      this.$emit('reload')
    },
    onSearch() {
      this.$emit('on-search')
    },
    trim(text, size) {
      if (!text) return text
      else if (text.length <= size) return text
      else {
        return text.slice(0, size) + '...'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.total-count {
  padding: 0 24px;
}

.custom-paginator {
  margin-top: 16px;
  text-align: center;
}

.search-bar {
  margin-bottom: 24px;
}
</style>