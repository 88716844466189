function loadView(component, path = '') {
  if (path[0] !== '/') {
    path = '/' + path
  }
  if (path[path.length - 1] === '/') {
    path = path.slice(0, path.length - 1)
  }

  return () => import(`@/views${path}/${component}.vue`)
}

export default [
  {
    path: 'dashboard',
    name: 'dashboard',
    meta: {
      title: '대시보드',
      icon: 'fa-columns',

    },
    component: loadView('Dashboard')
  },

  {
    path: 'user',
    name: 'user-list',
    meta: {
      title: '유저 목록',
      icon: 'fa-users',
    },
    component: loadView('UserList', 'user'),
  },
  {
    path: 'user/:id/editor',
    name: 'user-editor',
    meta: {
      title: '유저 편집기',
      hide: true,
      parent: 'user-list'
    },
    props: true,
    component: loadView('UserEditor', 'user'),
  },

  {
    path: 'user/:id/refund',
    name: 'user-refund-info',
    meta: {
      title: '유저 환불 정보',
      hide: true,
      parent: 'user-list'
    },
    props: true,
    component: loadView('UserRefundInfo', 'user'),
  },

  {
    path: 'product',
    name: 'product-list',
    meta: {
      title: '패키지 목록',
      icon: 'fa-dice-d6',
    },
    component: loadView('ProductList', 'product'),
  },
  {
    path: 'product/:id/editor',
    name: 'product-editor',
    meta: {
      title: '패키지 편집기',
      hide: true,
      parent: 'product-list'
    },
    props: true,
    component: loadView('ProductEditor', 'product'),
  },

  {
    path: 'lecture',
    name: 'lecture-list',
    meta: {
      title: '클래스 관리',
      icon: 'fa-graduation-cap',
    },
    component: loadView('LectureList', 'lecture'),
  },

  {
    path: 'lecture/:id/editor',
    name: 'lecture-editor',
    meta: {
      title: '클래스 편집기',
      parent: 'lecture-list',
      hide: true,
    },
    props: true,
    component: loadView('LectureEditor', 'lecture'),
  },

  {
    path: 'lecture/:lectureId/step',
    name: 'lecture-step-list',
    meta: {
      title: '클래스 회차 목록',
      hide: true,
    },
    props: true,
    component: loadView('StepList', 'lecture'),
  },
  {
    path: 'step/:id/editor',
    name: 'lecture-step-editor',
    meta: {
      title: '클래스 회차 편집기',
      hide: true,
    },
    props: true,
    component: loadView('StepEditor', 'lecture'),
  },


  {
    path: 'step/:stepId/exam',
    name: 'exam-list',
    meta: {
      title: '클래스 문제 목록',
      hide: true,
    },
    props: true,
    component: loadView('ExamList', 'exam'),
  },

  {
    path: 'exam/:id/editor',
    name: 'exam-editor',
    meta: {
      title: '문제 편집기',
      hide: true,
    },
    props: true,
    component: loadView('ExamEditor', 'exam'),
  },

  {
    path: 'ticket',
    name: 'ticket-list',
    meta: {
      title: '티켓 관리',
      icon: 'fa-ticket-alt'
    },
    component: loadView('TicketList', 'ticket'),
  },
  {
    path: 'ticket/bulk',
    name: 'ticket-bulk',
    meta: {
      title: '티켓 대량 생성',
      hide: true,
    },
    component: loadView('TicketBulk', 'ticket'),
  },
  {
    path: 'ticket/:id/editor',
    name: 'ticket-editor',
    meta: {
      title: '티켓 편집기',
      hide: true,
      parent: 'ticket-list'
    },
    props: true,
    component: loadView('TicketEditor', 'ticket'),
  },

  {
    path: 'coupon',
    name: 'coupon-list',
    meta: {
      title: '쿠폰 관리',
      icon: 'fa-stamp'
    },
    component: loadView('CouponList', 'coupon'),
  },
  {
    path: 'coupon/:id/editor',
    name: 'coupon-editor',
    meta: {
      title: '쿠폰 편집기',
      hide: true,
      parent: 'coupon-list'
    },
    props: true,
    component: loadView('CouponEditor', 'coupon'),
  },

  {
    path: 'review',
    name: 'review-list',
    meta: {
      title: '리뷰 목록',
      icon: 'fa-star-half-alt'
    },
    props: true,
    component: loadView('ReviewList', 'review'),
  },
  {
    path: 'review/:id/editor',
    name: 'review-editor',
    meta: {
      title: '리뷰 편집기',
      hide: true,
    },
    props: true,
    component: loadView('ReviewEditor', 'review'),
  },



  {
    path: 'policy',
    name: 'policy-list',
    meta: {
      title: '약관 관리',
      icon: 'fa-file-alt',
    },
    component: loadView('PolicyList', 'policy'),
  },
  {
    path: 'policy/:id/editor',
    name: 'policy-editor',
    meta: {
      title: '약관 편집기',
      hide: true,
      parent: 'policy-list'
    },
    props: true,
    component: loadView('PolicyEditor', 'policy'),
  },

  {
    path: 'faq',
    name: 'faq-list',
    meta: {
      title: 'FAQ 관리',
      icon: 'fa-question-circle',
    },
    component: loadView('FaqList', 'faq'),
  },
  {
    path: 'faq/:id/editor',
    name: 'faq-editor',
    meta: {
      title: 'FAQ 편집기',
      hide: true,
      parent: 'faq-list'
    },
    props: true,
    component: loadView('FaqEditor', 'faq'),
  },


  {
    path: 'pass/change',
    name: 'pass-change',
    meta: {
      title: '비밀번호 변경',
      hide: true,
    },
    component: loadView('PasswordChange'),
  },
]
