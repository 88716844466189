<template>
  <Tag :color="data[value]">
    <slot>{{ value }}</slot>
  </Tag>
</template>

<script>
export default {
  props: {
    value: {required: true},
    data: {type: Object, required: true},
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
</style>
