<template>
  <div class="page-header">
    <div class="header-info">
      <div class="page-title" v-if="title">{{ title }}</div>
      <div class="page-desc">{{ desc }}</div>
    </div>
    <div class="header-buttons">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {type: String},
    desc: {type: String},
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}
</style>
