<template>
  <div class="base-layout">
    <Layout :style="{height: '100%'}">
      <Sider class="app-menu" :width="220" hide-trigger>
        <CMenu>
        </CMenu>
      </Sider>
      <Layout>
        <Header class="app-header">
          <div class="header-navs">
            <div class="nav">
              <UserProfile></UserProfile>
            </div>
          </div>
        </Header>
        <Content class="app-content" :style="{padding: '0 24px 16px'}">
          <Breadcrumb :style="{margin: '20px 4px'}">
            <BreadcrumbItem v-for="bi in breadcrumbItems"
                            :to="{name: bi.name}"
                            :key="`bitem-${bi.name}`"
            >
              <span :style="{fontSize: '16px'}">{{ bi.label }}</span>
            </BreadcrumbItem>
          </Breadcrumb>
          <div class="content-container">
            <router-view></router-view>
          </div>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>

<script>
import CMenu from "@/components/menu/CMenu";
import UserProfile from "@/components/UserProfile";

export default {
  components: {UserProfile, CMenu},
  data() {
    return {}
  },
  computed: {
    breadcrumbItems() {
      const result = []

      this._.forEach(
          this.$route.matched,
          (item) => {
            if (item.meta && item.meta.parent) {
              const r = this.$router.resolve({name: item.meta.parent})
              result.push({
                name: r.route.name,
                label: r.route.meta.title,
              })
            }
            result.push({
              name: item.name,
              label: item.meta.title
            })
          })
      return result
    }
  }
}
</script>

<style scoped lang="scss">
.base-layout {
  height: 100%;

  .app-menu {
    height: 100%;
  }

  .app-header {
    background-color: white;
    box-shadow: 0 2px 3px 2px rgba(0, 0, 0, .1)
  }

  .app-content {
    .content-container {
      background-color: #fff;
      min-height: calc(100% - 80px);
      padding: 20px 36px;
      border: 1px solid #dcdee2;
      border-radius: 4px;
    }
  }
}

.app-header {
  .header-navs {
    height: 100%;
    display: flex;
    justify-content: flex-end;

    .nav {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
</style>
