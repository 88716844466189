<template>
  <Tag :size="size"
       :color="value ? trueType : falseType"
  >
    <slot name="true" v-if="value">{{ trueText }}</slot>
    <slot name="false" v-else>{{ falseText }}</slot>
  </Tag>
</template>

<script>
export default {
  props: {
    size: {type: String},
    value: {type: Boolean},
    text: {type: Array},
    type: {type: Array},
  },
  computed: {
    trueText() {
      if (this.text && this.text[0] != null) {
        return this.text[0]
      }
      return 'true'
    },
    falseText() {
      if (this.text && this.text[1] != null) {
        return this.text[1]
      }
      return 'false'
    },
    trueType() {
      if (this.type && this.type[1] != null) {
        return this.type[1]
      }
      return 'success'
    },
    falseType() {
      if (this.type && this.type[1] != null) {
        return this.type[1]
      }
      return 'default'
    },
  },
}
</script>

<style lang="scss" scoped>
</style>