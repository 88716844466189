import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  key: 'zinc',
  storage: window.localStorage,
  modules: [
      'user',
  ]
})

const store = new Vuex.Store({
  state: {
    user: {},
  },
  mutations: {
    setUser(state, val) {
      if (val) {
        state.user = val
      } else {
        state.user = {}
      }
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    authToken(state) {
      if (state.user) {
        return state.user.auth_token
      }
    },
  },
  plugins: [
      vuexLocal.plugin,
  ]
})


export default store
