<template>
  <div class="user-profile">
    <Dropdown trigger="click" @on-click="onDropdownClicked">
      <div class="user-profile-img img-fix">
        <img src="@/assets/images/placeholder-person.jpg">
      </div>
      <DropdownMenu slot="list">
        <DropdownItem name="pass-change">비밀번호 변경</DropdownItem>
        <DropdownItem name="logout">로그아웃</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    onDropdownClicked(item) {
      switch (item) {
        case 'pass-change':
          this.$router.push({name: 'pass-change'})
          break
        case 'logout':
          this.logout()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.user-profile {
  display: flex;
  align-items: center;
}

.user-profile-img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
</style>