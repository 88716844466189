<template>
  <DatePicker
      :value="localValue"
      @input="onInput"
      :format="dateFormat"
      :size="size"
      :placeholder="placeholder"
      :type="type"
      :time-picker-options="timeOptions"
  >
  </DatePicker>
</template>

<script>
export default {
  props: {
    value: {type: String},
    size: {type: String, default: 'default'},
    placeholder: String,
    type: {type: String, default: 'date'},
    format: {type: String},
    valueFormat: {type: String},
    timeOptions: {type: Object},
  },
  computed: {
    dateFormat() {
      if (this.format) return this.format;
      if (this.type === 'date') {
        return 'yyyy-MM-dd'
      } else if (this.type === 'datetime') {
        return 'yyyy-MM-dd HH:mm'
      }
    },
    dateValueFormat() {
      if (this.valueFormat) return this.valueFormat;
      if (this.type === 'date') {
        return 'YYYY-MM-DD'
      } else if (this.type === 'datetime') {
        return 'YYYY-MM-DD HH:mm:ss'
      }
    },
  },
  methods: {
    onInput(val) {
      this.localValue = val
      this.$emit('input', this.dateToString(val))
    },
    dateToString(val) {
      if (!val) return null;
      return this.$moment(val, this.dateValueFormat).format(this.dateValueFormat)
    },
    stringToDate(val) {
      if (!val) return null;
      return this.$moment(val, this.dateValueFormat).toDate()
    },
  },
  data() {
    return {
      localValue: null
    }
  },
  watch: {
    value(val) {
      this.localValue = this.stringToDate(val)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>