import _ from 'lodash'

const headers = {
  userList: [
    {title: 'ID', key: 'id', width: 80},
    {title: '계정', key: 'username'},
    {title: '이름', key: 'name'},
    {title: '가입일', key: 'created_at'},
    {title: '역할', key: 'role'},
    {title: '탈퇴 여부', key: 'is_signout', view: 'bool'},
    {title: '#', slot: 'action'},
  ],
  lectureList: [
    {title: 'ID', key: 'id', width: 80},
    {title: '제목', key: 'title'},
    {title: '회차 수', key: 'steps_count'},
    {title: '공개여부', key: 'is_open', view: 'bool', width: 100},
    {title: '#', slot: 'action'},
  ],
  stepList: [
    {title: 'ID', key: 'id', width: 80},
    {title: '순서', view: 'index', width: 70},
    {title: '제목', key: 'title'},
    {title: '문제 수', key: 'exams_count', width: 80},
    {title: '영상', key: 'video_name'},
    {title: '공개', key: 'is_open', view: 'bool', width: 90},
    {title: '#', slot: 'action'},
  ],
  examList: [
    {title: 'ID', key: 'id', width: 80},
    {title: '순서', view: 'index', width: 70},
    {title: '질문', key: 'question'},
    {title: '정답', key: 'answer_for_human', width: 80},
    {title: '보기 수', key: 'options_count', width: 80},
    {title: '#', slot: 'action'},
  ],
  ticketList: [
    {title: 'ID', key: 'id', width: 80},
    {title: '클래스 ID', key: 'lecture_id', width: 100},
    {title: '유저', key: 'username', view: 'link', target: 'user-editor', id: 'user_id'},
    {title: '시작일', key: 'start_at'},
    {title: '종료일', key: 'end_at'},
    {title: '생성일', key: 'created_at'},
    {title: '#', slot: 'action', width: 200},
  ],
  couponList: [
    {title: 'ID', key: 'id', width: 80},
    {title: '이름', key: 'name'},
    {title: '코드', key: 'code'},
    {title: '시작일', key: 'start_at'},
    {title: '종료일', key: 'end_at'},
    {title: '공개', key: 'is_open', view: 'bool', width: 90},
    {title: '#', slot: 'action', width: 200},
  ],
  productList: [
    {title: 'ID', key: 'id', width: 80},
    {title: '키워드 ', key: 'keyword'},
    {title: '이름', key: 'title'},
    {title: '기간', key: 'duration', width: 80},
    {title: '가격', key: 'price'},
    {title: '할인금액', key: 'discount_price'},
    {title: '클래스 수', key: 'lectures_count', width: 100},
    {title: '공개', view: 'bool', key: 'is_open', width: 95},
    {title: '메인', view: 'bool', key: 'is_main', width: 95},
    {title: '생성일', key: 'created_at'},
    {title: '#', slot: 'action', width: 200},
  ],
  policyList: [
    {title: 'ID', key: 'id', width: 80},
    {title: '이름', key: 'name'},
    {title: '키워드', key: 'keyword'},
    {title: '공개', view: 'bool', key: 'is_open', width: 95},
    {title: '생성일', key: 'created_at'},
    {title: '#', slot: 'action', width: 200},
  ],
  faqList: [
    {title: 'ID', key: 'id', width: 80},
    {title: '제목', key: 'title'},
    {title: '카테고리', key: 'category_for_human'},
    {title: '공개', view: 'bool', key: 'is_open', width: 95},
    {title: '생성일', key: 'created_at'},
    {title: '#', slot: 'action', width: 200},
  ],
  watchedList: [
    {title: 'ID', key: 'id', width: 80},
    {title: '클래스', key: 'lecture_title'},
    {title: '회차', key: 'step_title'},
    {title: '마지막 시청', key: 'last_watched_at'},
    {title: '첫 시청', key: 'start_at'},
  ],
  reviewList: [
    {title: 'ID', key: 'id', width: 80},
    {title: '유저', key: 'username', view: 'link', target: 'user-editor', id: 'user_id', width: 150},
    {title: '이름', key: 'name', width: 120},
    {title: '내용', key: 'body', view: 'trim', size: 80},
    {title: '베스트', view: 'bool', key: 'is_best', width: 95},
    {title: '#', slot: 'action', width: 200},
  ]
}

const customViews = ['bool', 'index', 'link', 'trim']

_.forEach(headers, (h, k) => {
  _.forEach(h, (item, index) => {
    if (customViews.includes(item.view)) {
      if (item.key) {
        headers[k][index].slot = item.key
      } else {
        headers[k][index].slot = item.view
      }
    }
  })
})

export default headers
