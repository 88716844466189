<template>
  <ErrorLayout :title="title" :desc="desc">
  </ErrorLayout>
</template>

<script>
import ErrorLayout from "@/layouts/ErrorLayout";
export default {
  components: {ErrorLayout},
  data() {
    return {
      title: '404',
      desc: '페이지를 찾을 수가 없습니다.'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>