<template>
  <Submenu :name="name">
    <template #title>
      <div :style="style">
        <CIcon :name="icon"/>
        <span><strong>{{ label }}</strong></span>
      </div>
    </template>
    <slot></slot>
  </Submenu>

</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
  },
  computed: {
    style() {
      return {
        display: 'flex',
        alignItems: 'center',
      }
    }
  }
}
</script>

<style lang="scss" scoped>
i {
  margin-right: 6px;
}
</style>