<template>
  <div class="filter-box">
  </div>
</template>

<script>
export default {
  props: {

  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
