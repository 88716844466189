<template>
  <Menu class="app-menu"
        :theme="theme" width="220px"
        :active-name="$route.name"
        :open-names="openNames">
    <template v-for="menu in menus">
      <CMenuItem
          v-if="!hasChild(menu)"
          :name="menu.name"
          :label="menu.meta.title"
          :icon="menu.meta.icon"
          :key="`menu-${menu.name}`"
      ></CMenuItem>
      <CSubmenu :name="menu.name"
                :label="menu.meta.title"
                :icon="menu.meta.icon"
                v-else>
        <CMenuItem
            v-for="submenu in getSubmenuChildren(menu)"
            :name="submenu.name"
            :label="submenu.meta.title"
            :icon="submenu.meta.icon"
            :key="`menu-${submenu.name}`"
        >
        </CMenuItem>
      </CSubmenu>
    </template>
  </Menu>
</template>

<script>
import CMenuItem from "./CMenuItem";
import CSubmenu from "./CSubmenu";
import routes from '@/router/routes'

export default {
  components: {
    CMenuItem, CSubmenu,
  },
  props: {
    theme: {
      type: String, default: 'dark'
    },
    width: {
      type: String, default: '220px'
    },
  },
  computed: {
    menus() {
      return this._.filter(routes, (item) => {
        return !item.meta.hide
      })
    },
    openNames() {
      if (!this.hasParentRoute) {
        return []
      }
      return [this.parentRoute.name]
    }
  },
  methods: {
    hasChild(menu) {
      return menu.children && this.getSubmenuChildren(menu).length > 0
    },
    getSubmenuChildren(menu) {
      return this._.filter(menu.children, (item) => {
          return !item.meta.hide
      })
    }
  },
}
</script>

<style scoped lang="scss">

</style>