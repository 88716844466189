<template>
  <div class="content-scaffold">
    <div class="content-header" v-if="headerExists">
      <PageHeader :desc="desc" :title="title">
        <div class="l-gap-buttons">
          <slot name="buttons"></slot>
        </div>
      </PageHeader>
    </div>

    <slot></slot>
  </div>
</template>

<script>

export default {
  props: {
    title: {type: String},
    desc: {type: String},
  },
  computed: {
    headerExists() {
      return this.$slots.buttons || this.desc
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.content-header {
  margin-bottom: 24px;
}
</style>